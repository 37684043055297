import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import useWhyDidYouUpdate from "../../hooks/useWhyDidYouUpdate";
import {IMedia} from "../../interfaces/common";
import Media from "./Media";
import MediaViewerPopup from "./MediaViewerPopup";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import Loader from "../loader/Loader";
import useUpload from "../../hooks/useUpload";
import UploadingMedia from "../UploadingMedia";
import {clearSelection, selectMediaRange, selectMediaSingle} from "../../store/app/actions";
import {routeHash} from "../../utils/utils";
import MediaDeleteModal from "./MediaDeleteModal";
import Api from "../../api/api";

interface MediaListProps {

}


const MediaList: FC<MediaListProps> = (props) => {
    useWhyDidYouUpdate("MediaList", props);

    const {
        media,
        mediaLoading,
        activeCategory,
        selectedMedia,
        isAuthorized
    } = useSelector((store: ApplicationState) => store.app);

    const [showMedia, setShowMedia] = useState<IMedia | undefined>();
    const [showMediaDeleteModal, setShowMediaDeleteModal] = useState<IMedia | IMedia[] | undefined>();


    const dispatch = useDispatch();
    const {handleInput, uploadingItems} = useUpload();

    const [selectStartIdx, setSelectStartIdx] = useState<number | undefined>(undefined);
    const activeIndexRef = useRef<number | null>(null)

    useEffect(() => {
        const currentIdx = activeIndexRef.current;
        if (showMedia && typeof currentIdx === "number") {

            let prevIdx = currentIdx - 1 >= 0 ? currentIdx - 1 : media.length - 1;
            let nextIdx = currentIdx + 1 < media.length ? currentIdx + 1 : 0;

            let nextImage = new Image();
            nextImage.src = media[nextIdx].url;

            let prevImage = new Image();
            prevImage.src = media[prevIdx].url;

        }
    }, [showMedia]);

    const __onPrev = useCallback(() => {
        // setLoading(true);
        let imgIndex = activeIndexRef.current;
        // const imgIndex = media.findIndex(_ => _.id === showMedia?.id);
        if (typeof imgIndex === "number") {
            const prevIdx =  imgIndex - 1 >= 0 ? imgIndex - 1 : media.length - 1;
            activeIndexRef.current = prevIdx;
            setShowMedia(media[prevIdx]);
        }
    }, [media, activeIndexRef])

    const __onNext = useCallback(() => {
        let imgIndex = activeIndexRef.current;
        if (typeof imgIndex === "number") {
            const nextIdx =  imgIndex + 1 < media.length ? imgIndex + 1 : 0;
            activeIndexRef.current = nextIdx;
            setShowMedia(media[nextIdx]);

            // if ((imgIndex + 1) < media.length) {
            //     activeIndexRef.current = imgIndex + 1;
            //     setShowMedia(media[imgIndex + 1])
            // } else {
            //     activeIndexRef.current = 0;
            //     setShowMedia(media[0]);
            // }
        }
    }, [media, activeIndexRef])

    const __keyListener = (e: KeyboardEvent) => {

        switch (e.key) {
            case "Backspace":
            case "Delete":
                if (isAuthorized && selectedMedia.length > 0) {
                    setShowMediaDeleteModal(selectedMedia);
                }
                break;
            case "Escape":

                setShowMedia(undefined);
                __deselect();
                break;
            case "ArrowLeft":
                if (!showMedia) return;
                __onPrev()
                break;
            case "ArrowRight":
                if (!showMedia) return;
                __onNext()
                break;
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", __keyListener, false);

        return () => {
            document.removeEventListener("keydown", __keyListener);
        }
    }, [showMedia]);

    const __onMediaClick = useCallback((__media: IMedia, event: React.MouseEvent) => {
        event.stopPropagation()

        if (event.ctrlKey || event.metaKey) {
            dispatch(selectMediaSingle(__media))
            return
        }
        if (event.shiftKey) {
            if (!selectStartIdx) {
                setSelectStartIdx(media.findIndex(_ => _.id === __media.id));
            } else {
                const endIdx = media.findIndex(_ => _.id === __media.id);
                dispatch(selectMediaRange(selectStartIdx, endIdx))
            }
            return
        }

        activeIndexRef.current = media.findIndex(_ => _.id === __media.id);
        setShowMedia(__media);
    }, [media, selectStartIdx]);

    const __onCloseMedia = useCallback(() => {

        setShowMedia(undefined);
    }, []);


    const __deselect = () => {
        setSelectStartIdx(undefined)
        dispatch(clearSelection())
    }

    const __onMediaDeleteSelected = () => {
        setShowMediaDeleteModal(selectedMedia);
    }

    const __onMediaDelete = useCallback((media: IMedia) => {
        setShowMediaDeleteModal(media);
    }, []);

    useEffect(() => {
        if (!mediaLoading) {
            if (window.location.hash) {
                const hash = routeHash(window.location.hash)
                if ("media" in hash) {
                    activeIndexRef.current = media.findIndex(_ => _.id === parseInt(String(hash.media)));
                    const __media = media.find(_ => _.id === parseInt(hash.media));
                    if (__media) {
                        setShowMedia(__media);
                    } else {
                        Api.getMedia(hash.media)
                            .then(data => {
                                setShowMedia(data.data);
                            })
                    }
                }
            }
        }
    }, [media, mediaLoading]);



    useEffect(() => {

    }, [uploadingItems]);

    if (mediaLoading) return <Loader/>;


    return (
        <>
            {selectedMedia.length > 0 && <div className="media-actions">
                <button className="btn btn-mini btn-danger" onClick={__onMediaDeleteSelected}>Elimina selezionato
                </button>
            </div>}

            <div className="media-list" onClick={__deselect} style={{
                placeItems: isAuthorized ? "start" : "center",
            }}>
                {(isAuthorized && activeCategory) && <AddButton onChange={handleInput}/>}
                {uploadingItems.map(_ => <UploadingMedia key={_.id} file={_}/>)}

                {media.length > 0 ?
                    media.map(__media => {
                        return <Media key={`media-${__media.id}`}
                                      media={__media}
                                      onClick={__onMediaClick}
                                      onDelete={__onMediaDelete}
                        />
                    })
                    :
                    (isAuthorized ? null : <div className="not-found">No media found</div>)}
            </div>


            {showMedia && <MediaViewerPopup
                media={showMedia!}
                onClose={__onCloseMedia}
                onNext={__onNext}
                onPrev={__onPrev}
            />}
            {showMediaDeleteModal &&
                <MediaDeleteModal media={showMediaDeleteModal} onClose={() => setShowMediaDeleteModal(undefined)}/>}
        </>
    );
};

interface AddButtonProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const AddButton: FC<AddButtonProps> = ({onChange}) => {
    //o<br/> copia/incolla o<br/> trascina e rilascia
    return <div className="add-image">
        <input type="file" multiple onChange={onChange}/>
        <div style={{fontSize: "100px"}}>+</div>
        <div>Aggiungi immagine</div>
    </div>
}

export default MediaList;

