import {Action, ActionCreator, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {ApplicationState} from '../index';
import {AppActions} from "./types";
import {ICategory, IMedia, ITag, IUploadParams} from "../../interfaces/common";
import API from "../../api/api";
import AuthService from "../../services/AuthService";

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const logout: AppThunk = () => {
    return (dispatch: Dispatch): any => {
        dispatch({type: AppActions.LOGOUT})
        AuthService.logout();
    }
}
export const login: AppThunk = (authData: any) => {
    return (dispatch: Dispatch): any => {
        dispatch({type: AppActions.LOGIN, payload: authData})
    }
}
type NotificationType = "default" | "error" | "warning";
export const showNotification: AppThunk = (text: string, type: NotificationType) => {
    return (dispatch: Dispatch): any => {
        dispatch({
            type: AppActions.SHOW_NOTIFICATION, payload: {
                text, type,
            }
        })
    }
}
export const hideNotification: AppThunk = (notification: any) => {
    return (dispatch: Dispatch): any => {
        dispatch({type: AppActions.HIDE_NOTIFICATION})
    }
}

export const selectMediaSingle: AppThunk = (media: IMedia) => {
    return (dispatch: Dispatch): any => {
        dispatch({type: AppActions.SELECT_MEDIA, payload: media});
    }
}

export const selectMediaRange: AppThunk = (start: number, end: number) => {
    return (dispatch: Dispatch): any => {
        dispatch({type: AppActions.SELECT_MEDIA_RANGE, payload: {start, end,}});
    }
}

export const clearSelection: AppThunk = (start: number, end: number) => {
    return (dispatch: Dispatch): any => {
        dispatch({type: AppActions.CLEAR_SELECTION, payload: {}});
    }
}

export const moveMediaToCategory: AppThunk = (media: IMedia, category: ICategory) => {
    return (dispatch: Dispatch): any => {

        dispatch({
            type: AppActions.MOVE_MEDIA_TO_CATEGORY, payload: {
                media,
                category
            }
        })
        API.updateMedia(media.id, {
            ...media,
            categoryId: category.id
        })
    }
}

export const search: AppThunk = (query: string) => {
    return (dispatch: Dispatch): any => {
        dispatch({type: AppActions.UPDATE_HEADER, payload: `Search results: ${query}`})
        API.search(query)
            .then((data) => {
                const medias = data.data;
                dispatch({type: AppActions.LOAD_MEDIA, payload: medias})
            })
    }
}

export const initialLoad: AppThunk = (categoryId?: number) => {
    return (dispatch: Dispatch): any => {
        API.getIndexData(categoryId)
            .then((data) => {
                const {medias, tags, categories} = data.data;
                dispatch({type: AppActions.LOAD_TAGS, payload: tags})
                dispatch({type: AppActions.LOAD_CATEGORIES, payload: categories})

                if (categoryId) {
                    dispatch({
                        type: AppActions.SET_ACTIVE_CATEGORY,
                        payload: categories.find(_ => _.id === parseInt(String(categoryId)))
                    })
                }

                dispatch({type: AppActions.LOAD_MEDIA, payload: medias})

                findDuplicates(medias.map(_ => _.id), dispatch);
            })
    }
}

const findDuplicates = (ids: number[], dispatch: any) => {
    API.findDuplicates(ids)
        .then((data) => {
            const medias = data.data;
            dispatch({type: AppActions.UPDATE_MEDIAS, payload: medias})
        })
}

export const loadTags: AppThunk = () => {
    return (dispatch: Dispatch): any => {
        API.getTags()
            .then(data => {
                const tags = data.data;
                dispatch({type: AppActions.LOAD_TAGS, payload: tags})
            })
    }
}


export const updateCategory: AppThunk = (category: ICategory, callback: (category: ICategory) => void) => {
    return (dispatch: Dispatch): any => {
        API.updateCategory(category.id, category)
            .then(data => {
                const category = data.data;
                callback(category);
                dispatch({type: AppActions.UPDATE_CATEGORY, payload: category})
                dispatch({type: AppActions.UPDATE_HEADER, payload: category.title})
            });
    }
}

export const deleteCategory: AppThunk = (category: ICategory, callback: () => void) => {
    return (dispatch: Dispatch): any => {
        API.deleteCategory(category)
            .then(data => {
                dispatch({type: AppActions.DELETE_CATEGORY, payload: category})
                callback();
            })
    }
}


export const loadCategories: AppThunk = () => {
    return (dispatch: Dispatch): any => {
        API.getIndexData()
            .then(data => {
                const {tags, categories} = data.data;
                dispatch({type: AppActions.LOAD_TAGS, payload: tags})
                dispatch({type: AppActions.LOAD_CATEGORIES, payload: categories})
            })
    }
}

export const createTag: AppThunk = (tag, callback: (tag: ITag) => void) => {
    return (dispatch: Dispatch): any => {
        API.createTag(tag)
            .then(data => {
                dispatch({type: AppActions.ADD_TAG, payload: data.data})
                callback(data.data);
            })
    }
}

export const setActiveTag: AppThunk = (tag: ITag) => {
    return (dispatch: Dispatch): any => {
        dispatch({type: AppActions.SET_ACTIVE_TAG, payload: tag})
        API.getTagMedias(tag)
            .then(data => {
                const media: IMedia[] = data.data;
                media.sort((a, b) => a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)
                dispatch({type: AppActions.LOAD_MEDIA, payload: media})
                findDuplicates(media.map(_ => _.id), dispatch);
            })
    }
}

export const setActiveCategory: AppThunk = (category: ICategory) => {
    return (dispatch: Dispatch): any => {
        dispatch({type: AppActions.SET_ACTIVE_CATEGORY, payload: category})
        window.history.pushState({}, "", `/category/${category.id}`)
        document.title = `Foto ${category.title} | ${process.env.REACT_APP_TITLE}`;
        API.getCategoryMedia(category.id)
            .then(data => {
                const media: IMedia[] = data.data;
                media.sort((a, b) => a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)
                dispatch({type: AppActions.LOAD_MEDIA, payload: media});
                findDuplicates(media.map(_ => _.id), dispatch);
            })
    }
}

export const toggleMenu: AppThunk = () => {
    return (dispatch: Dispatch): any => {
        dispatch({type: AppActions.TOGGLE_MENU,})
    }
}

export const updateMedia: AppThunk = (media: IMedia, callback: (response: any) => void) => {
    return (dispatch: Dispatch): any => {
        API.updateMedia(media.id, media)
            .then(data => {
                dispatch({type: AppActions.UPDATE_MEDIA, payload: data.data})
                callback(data.data)
            })
    }
}

export const deleteMultipleMedia: AppThunk = (medias: IMedia[], callback: (response: any) => void) => {
    return (dispatch: Dispatch): any => {
        dispatch({type: AppActions.DELETE_SELECTED_MEDIA, payload: medias})
        API.deleteMedias(medias.map(_ => _.id))
            .then(data => {
                callback(data.data)
            })
    }
}

export const deleteMedia: AppThunk = (media: IMedia, callback: (response: any) => void) => {
    return (dispatch: Dispatch): any => {
        dispatch({type: AppActions.DELETE_MEDIA, payload: media})
        API.deleteMedia(media.id)
            .then(data => {
                callback(data.data)
            })
    }
}


export const editMedia: AppThunk = (media: IMedia, data: string, callback: (response: any) => void) => {
    return (dispatch: Dispatch): any => {
        API.editMedia(media.id, data)
            .then((data) => {
                dispatch({type: AppActions.UPDATE_MEDIA, payload: data.data})
                dispatch({type: AppActions.SHOW_NOTIFICATION, payload: "Saved"})
            })
    }
}

export const uploadFile: AppThunk = (file: File, params: IUploadParams, callback: (response: any) => void, onProgress: (event: any) => void) => {
    return (dispatch: Dispatch): any => {
        const form = new FormData();
        form.append("file", file);
        if (params.tag && params.tag.id !== 0) {
            form.append("tag", params.tag.id.toString());
        }
        if (params.category && params.category.id !== 0) {
            form.append("category", params.category.id.toString());
        }
        API.uploadFile(form, onProgress)
            .then(data => {
                if (typeof data.data !== "string") {
                    dispatch({type: AppActions.UPLOAD_MEDIA, payload: data.data})
                    callback(data.data)
                } else {
                    callback(false);
                }
            })
            .catch(e => {
                console.log(e);
            })
    }
}
//
// export const uploadFileToCategory: AppThunk = (categoryId: number, file: File, callback: (response: any) => void) => {
//     return (dispatch: Dispatch): any => {
//         const form = new FormData();
//         form.append("file", file);
//         form.append("category", categoryId.toString())
//         API.uploadFile(form)
//             .then(data => {
//                 dispatch({type: AppActions.UPLOAD_MEDIA, payload: data.data})
//                 callback(data.data)
//             })
//     }
// }
