import React, {FC, useEffect, useState} from 'react';
import useWhyDidYouUpdate from "../hooks/useWhyDidYouUpdate";
import {useDispatch, useSelector} from "react-redux";
import {initialLoad, logout, toggleMenu} from "../store/app/actions";
import {ApplicationState} from "../store";
import MediaList from "../components/media/MediaList";
import LoginPopup from "../components/LoginPopup/LoginPopup";
import AuthService from "../services/AuthService";
import Notification from "../components/Notification/Notification";
import Sidebar from "../components/Sidebar";
import Search from "../components/Search";
import useUpload from "../hooks/useUpload";
import Header from "../components/Header";
import {routeUrl} from "../utils/utils";
import {Close, Menu} from "react-ionicons";

interface HomeProps {

}

const Home: FC<HomeProps> = (props) => {
    useWhyDidYouUpdate("Home", props);
    const {} = props;

    const {notification, isAuthorized, menuOpen} = useSelector((store: ApplicationState) => store.app);

    const dispatch = useDispatch();

    const {handleDrop, handlePaste} = useUpload();

    const [showLoginPopup, setShowLoginPopup] = useState(false);

    useEffect(() => {
        AuthService.isAuthorized(dispatch)
        const route = routeUrl(window.location.pathname);

        dispatch(initialLoad(route.category));

        window.addEventListener("paste", handlePaste);
        return () => {
            window.removeEventListener("paste", handlePaste);
        }
    }, [dispatch,]);


    return (
        <div className="gallery">
            <Sidebar/>

            <div className="media-view"
                 onDrop={handleDrop}
                 onDragOver={(e) => e.preventDefault()}
            >
                <div className="header">

                    <Header/>
                    <div className="actions">
                        <div className="mobile-menu btn btn-edit" onClick={() => dispatch(toggleMenu())}>
                            {menuOpen ? <Close color={"#fff"} width={40} height={40}/> :
                                <Menu color={"#fff"} width={"40"} height={"40"}/>}
                        </div>

                        <Search/>

                        {isAuthorized ?
                            <button className="login-btn active" onClick={() => dispatch(logout())}>Logout</button>
                            :
                            <button className="login-btn" onClick={() => setShowLoginPopup(true)}>Log in</button>
                        }
                    </div>
                </div>
                <MediaList/>
            </div>

            {showLoginPopup && <LoginPopup onClose={() => setShowLoginPopup(false)}/>}
            {notification && <Notification notification={notification}/>}
        </div>
    );
};

export default Home;
