import React from 'react';
import './App.scss';
import Home from "./screens/Home";
import {Provider} from 'react-redux'
import configureStore from "./configureStore";

const initialState: any = {};

const store = configureStore(initialState);

function App() {

    return <Provider store={store}>
        <Home/>
    </Provider>
}

export default App;
