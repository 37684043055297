import React, {FC, useRef, useState} from 'react'
import useWhyDidYouUpdate from "../hooks/useWhyDidYouUpdate"
import {useDispatch, useSelector} from "react-redux"
import {ApplicationState} from "../store"
import {Pencil, Save, Trash, CloudDownload} from 'react-ionicons'
import {updateCategory} from "../store/app/actions"
import CategoryDeleteModal from "./category/CategoryDeleteModal"

interface HeaderProps {

}

const Header: FC<HeaderProps> = (props) => {
    useWhyDidYouUpdate("Header", props)
    // const {} = props
    const {
        headerText,
        activeCategory,
        selectedMedia,
        isAuthorized
    } = useSelector((store: ApplicationState) => store.app)
    const dispatch = useDispatch()

    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const inputRef = useRef<HTMLInputElement>(null)
    const [editTitle, setEditTitle] = useState<string>("")

    const __onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && editTitle !== "") {
            __onSave()
        } else if (e.key === "Escape") {
            setEditTitle("")
        }
    }

    const __onSave = () => {
        if (editTitle !== "" && editTitle !== activeCategory?.title) {
            dispatch(updateCategory({
                ...activeCategory,
                title: editTitle,
            }, () => {
                setEditTitle("")
            }))
        } else {

        }
        setEditTitle("")
    }

    const __onEdit = () => {
        setEditTitle(activeCategory!.title)
        setTimeout(() => {
            inputRef.current?.focus()
        }, 50)
    }

    const __onDownload = (e: any) => {

    }

    return (
        <>

            <div className="title">
                <div className="text">
                    {isAuthorized && <input style={{
                        opacity: (editTitle ? 1 : 0)
                    }} value={editTitle?.toString()} ref={inputRef} onChange={e => setEditTitle(e.target.value)}
                                            onKeyDown={__onKeyDown}/>}
                    <h1 style={{
                        opacity: (editTitle ? 0 : 1)
                    }}>{!!headerText ? headerText : process.env.REACT_APP_TITLE}</h1>
                </div>

                {(isAuthorized && activeCategory && selectedMedia.length === 0) && <div className="actions">
                    <a href={`/api/category/${activeCategory.id}/download`} target="_blank" ><CloudDownload color={'#000000'}/></a>
                    {editTitle && <button onClick={__onSave}><Save color={'#000000'}/></button>}
                    {!editTitle && <button onClick={__onEdit}><Pencil color={'#000000'}/></button>}

                    <button onClick={() => setShowDeleteModal(true)}><Trash color={'#ff0000'}/></button>
                </div>}
            </div>

            {(showDeleteModal && activeCategory) &&
                <CategoryDeleteModal category={activeCategory!} onClose={() => setShowDeleteModal(false)}/>}
        </>
    )
}

export default Header
