import React, {FC} from 'react';
import useWhyDidYouUpdate from "../hooks/useWhyDidYouUpdate";
import {UploadingFile} from "../hooks/useUpload";

interface UploadingMediaProps {
    file: UploadingFile,
}

const UploadingMedia: FC<UploadingMediaProps> = (props) => {
    useWhyDidYouUpdate("UploadingMedia", props);
    const {file} = props;
    const ObjectURL = URL.createObjectURL(file.file);

    return (
        <div className="media-container uploading">
            <img src={ObjectURL} alt=""/>
            <span>{`${file.progress}%`}</span>
        </div>
    );
};

export default UploadingMedia;
