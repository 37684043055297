import {DragEvent, useState} from "react";
import {showNotification, uploadFile} from "../store/app/actions";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../store";

export interface UploadingFile {
    file: File,
    id: string
    progress: number,
}

export default function useUpload() {
    const {activeCategory, activeTag, isAuthorized} = useSelector((store: ApplicationState) => store.app);
    const dispatch = useDispatch();

    const [uploadingItems, setUploadingItems] = useState<UploadingFile[]>([]);

    const __uploadFiles = (files: File[]) => {
        files.forEach((file) => {
            const __file = {
                id: Math.random().toString(32),
                file,
                progress: 0,
            }
            setUploadingItems((prevState) => {
                return [
                    ...prevState,
                    __file
                ];
            })

            dispatch(uploadFile(file, {tag: activeTag, category: activeCategory}, (response: any) => {
                if (response) {
                    setUploadingItems((prevState) => {
                        return prevState.filter(_ => _.file !== file);
                    });
                    if (response.duplicates && response.duplicates.length > 0) {
                        dispatch(showNotification("Il file caricato potrebbe avere dei duplicati", "warning"))
                    } else {
                        dispatch(showNotification("Caricato", "default"))
                    }
                } else {
                    dispatch(showNotification("Errore di caricamento", "error"))
                }
            }, (e: ProgressEvent) => {
               __file.progress = Math.round(e.loaded * 100 / e.total);
            }))


        })
    }

    const handlePaste = (e: any) => {
        if (!isAuthorized) return false;
        e.preventDefault();
        const files = [];
        const items = e.clipboardData?.items;
        for (let index in items) {
            const item = items[index];
            if (item.kind === 'file') {
                files.push(item.getAsFile());
            }
        }

        __uploadFiles(files);
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!isAuthorized) return false;
        e.preventDefault();
        const files: File[] = [];
        if (e.target.files) {
            for (let i = 0; i < e.target.files.length; i++) {
                files.push(e.target.files[i]);
            }
        }

        __uploadFiles(files);
    }

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        if (!isAuthorized) return false;
        e.preventDefault();
        const files = [];

        for (let i = 0; i < e.dataTransfer.files.length; i++) {
            const file = e.dataTransfer.files[i];
            files.push(file);
        }

        __uploadFiles(files);
    }

    return {
        handlePaste,
        handleInput,
        handleDrop,
        uploadingItems
    }

}
