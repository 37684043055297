import {ICategory, IMedia, ITag} from "../../interfaces/common";

export enum AppActions {
    LOGIN = "LOGIN",
    LOGOUT = "LOGOUT",

    TOGGLE_MENU = "TOGGLE_MENU",


    SHOW_NOTIFICATION = "SHOW_NOTIFICATION",
    HIDE_NOTIFICATION = "HIDE_NOTIFICATION",

    ADD_TAG = "ADD_TAG",
    LOAD_TAGS = "LOAD_TAGS",
    SET_ACTIVE_TAG = "SET_ACTIVE_TAG",
    LOAD_TAG_MEDIA = "LOAD_TAG_MEDIA",

    ADD_CATEGORY = "ADD_CATEGORY",
    LOAD_CATEGORIES = "LOAD_CATEGORIES",
    UPDATE_CATEGORY = "UPDATE_CATEGORY",
    DELETE_CATEGORY = "DELETE_CATEGORY",

    SET_ACTIVE_CATEGORY = "SET_ACTIVE_CATEGORY",
    MOVE_MEDIA_TO_CATEGORY = "MOVE_MEDIA_TO_CATEGORY",

    LOAD_MEDIA = "LOAD_MEDIA",
    UPDATE_MEDIA = "UPDATE_MEDIA",
    UPDATE_MEDIAS = "UPDATE_MEDIAS",
    DELETE_MEDIA = "DELETE_MEDIA",
    DELETE_SELECTED_MEDIA = "DELETE_SELECTED_MEDIA",
    UPLOAD_MEDIA = "UPLOAD_MEDIA",
    SELECT_MEDIA = "SELECT_MEDIA",
    SELECT_MEDIA_RANGE = "SELECT_MEDIA_RANGE",
    CLEAR_SELECTION = "CLEAR_SELECTION",

    UPDATE_HEADER = "UPDATE_HEADER",
}

export interface appState {
    menuOpen: boolean

    isAuthorized: boolean
    notification: any

    headerText: string

    tags: ITag[]
    activeTag: ITag | null

    categories: ICategory[]
    activeCategory: ICategory | null
    mediaLoading: boolean
    media: IMedia[]
    selectedMedia: IMedia[]
}
