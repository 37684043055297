import React, {DragEvent, FC, useCallback, useState} from 'react';
import {ICategory} from "../../interfaces/common";
import useWhyDidYouUpdate from "../../hooks/useWhyDidYouUpdate";
import CategoryItemList from "./CategoryItemList";
import {setActiveCategory, moveMediaToCategory} from "../../store/app/actions";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import CategoryCreate from "./CategoryCreate";

interface CategoryItemProps {
    category: ICategory
}

const CategoryItem: FC<CategoryItemProps> = (props) => {
    useWhyDidYouUpdate(`CategoryItem-${props.category.id}`, props);
    const {category, } = props;
    const {activeCategory} = useSelector((store: ApplicationState) => store.app);
    const dispatch = useDispatch();
    const [isExpanded, setIsExpanded] = useState(false);

    const __onClick = useCallback((e) => {
        e.stopPropagation();

        setIsExpanded(prev => !prev);
        if (activeCategory?.id !== category.id) {
            dispatch(setActiveCategory(category));
        }

        if (!isExpanded) {
            // onClick(category);
        }
    }, [activeCategory, dispatch]);

    const __onCreateCategory = useCallback((category) => {
        setIsExpanded(true);
        dispatch(setActiveCategory(category));
    }, [dispatch]);



    const __onDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        const type = e.dataTransfer.getData("type");
        if (type === "media") {
            const media = JSON.parse(e.dataTransfer.getData("media"));
            dispatch(moveMediaToCategory(media, category));
        }

        const target = e.target as HTMLDivElement;

        target.classList.remove('drag-over')
    }

    const __onDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    }

    const __onDragEnter = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const target = e.target as HTMLDivElement;
        target.classList.add('drag-over')
    }
    const __onDragLeave = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const target = e.target as HTMLDivElement;

        target.classList.remove('drag-over')
    }

    return (
        <div className={"category-item"}>
            <div
                className={`category-title ${activeCategory?.id === category.id ? "active" : ""}`}
                onClick={__onClick}

                onDrop={__onDrop}
                onDragOver={__onDragOver}
                onDragEnter={__onDragEnter}
                onDragLeave={__onDragLeave}
            >
                <span>{category.children.length > 0 ? <Expand isExpanded={isExpanded}/> : ""} {category.title} {(category.count! > 0) && `(${category.count})`}</span>

                <CategoryCreate parentCategory={category} onCreate={__onCreateCategory}/>
            </div>

            {category.children.length > 0 &&
                <CategoryItemList isExpanded={isExpanded} categories={category.children!}/>}
        </div>
    );
};

export default CategoryItem;

interface ExpandProps {
    isExpanded: boolean
}


const Expand: FC<ExpandProps> = ({isExpanded}) => {

    return <span className="expand">{isExpanded ? "▼" : "►"}</span>
}
